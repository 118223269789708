import { Theme } from "@mui/material/styles";

const styles = (theme:Theme) => ({
   container:{
    width:"100%",
    maxWidth:1100,
    height:770,
   // height:"auto",
   // minHeight:693,
    border: "2px solid #E9E9E9",
    display:"flex",
    flexDirection:"column",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14.5,
    lineHeight: "140%",
    letterSpacing: "-0.006em",
    color: "#000000",
   },
   tHead:{
    width:"100%",
    display:"flex",
    height:77,
    alignItems:"center",
    paddingRight:20,
    paddingLeft:20,
    borderBottom: "2px solid #E9E9E9",
    fontWeight:600
   },
   tDataRow:{
      width:"100%",
      display:"flex",
      height:77,
      alignItems:"center",
      paddingRight:20,
      paddingLeft:20,
      borderBottom: "2px solid #E9E9E9",
      "&:hover":{
         backgroundColor:"#e6f1fa"
      }
   },
   tData:{
    height:616,
   // height:"auto",
   // minHeight:616,
    display:"flex",
    flexDirection:"column",
    width:"100%",
   },
   noData:{
    alignItems:"center",
    justifyContent:"center"
   },
   paginationContainer:{
    width:"100%",
    display:"flex",
    height:77,
    alignItems:"center",
    paddingRight:20,
    paddingLeft:20,
    borderTop: "2px solid #E9E9E9",
    justifyContent:"space-between",
   },
   index:{
      fontWeight: 600,
   },
   lastTData:{
      borderBottom:"unset"
   }

}) as const ;

export default styles;