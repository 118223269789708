import React, { useEffect } from 'react'
import BaseModal from 'src/components/base-modal'
import { 
  Button ,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import { Theme } from "@mui/material/styles";
import { Formik,Form } from 'formik';
import * as Yup from 'yup';
import { themeFieldsData } from './mockData';
import ConfirmModal from 'src/components/confirm-modal';

import { deriveInitalValues } from './schema';
import useApi from 'src/hooks/useApi';



const validationSchema = Yup.object({
  tname: Yup.string().required('This field is required'),
  no_of_records: Yup.string().required('This field is required'),
});

const useStyles = makeStyles((theme:Theme) => ({
    container: {
      width:"100%",
      display:"flex",
      flexDirection:"column",
      padding:30,
      alignItems:"center"
    },
    actionContainer:{
        width:"100%",
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-end",
        marginTop:50,
        '& .MuiButton-root': {
            marginLeft: 20 ,
            maxWidth:100
          },
    },
    title:{
      fontSize:22,
      fontWeight:700,
      textTransform:"capitalize"
     },
     fieldSection:{
      width:"100%",
      display:"flex",
      flexDirection:"column"
     },
     fieldLabel:{
        // fontWeight:500,
        marginTop:20
     },
     checkboxContainer:{
        width:"100%",
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridGap: 5,
        marginTop:5,
        height:300,
        overflowY:"auto",
        '&::-webkit-scrollbar': {
          width: 10
        },
        '&::-webkit-scrollbar-thumb':{
          backgroundColor: "#ccc",
          borderRadius: 10,
        },
        '&::-webkit-scrollbar-track':{
          backgroundColor: '#f1f1f1',
          borderRadius: 10
        }
        
     }
  }));

type ModalProps = {
    type:"create"|"edit",
    open:boolean,
    handleClose:()=>void,
    themeDetails?:{tid:number|null,tname:string,no_of_records:number|null,open?:boolean},
    onSuccess?:(values:any)=>void,
    setFetchThemes?:(value:boolean) => void
}




  

export default function ThemeModal(props:ModalProps) {
    const {open,handleClose,type,themeDetails,onSuccess,setFetchThemes} = props
    const classes = useStyles()

    const [confirmModalOpen ,setConfirmModalOpen] = React.useState(false)

    const handleConfirmModalClose = () => {
      setConfirmModalOpen(false)
    }

    const handleConfirmModalOpen = () => {
      setConfirmModalOpen(true)
    }

    const {data:createdThemeData, loading:createThemeLoading, error:themeCreationError ,fetchData:createTheme}= useApi(
      '/addNewTheme',
      "POST",
  )

  const {data:editThemeData, loading:editThemeLoading, error:themeEditError ,fetchData:editTheme}= useApi(
    '/editTheme',
    "POST",
)

  const {data:fieldData, loading:fieldDataFetching, error:filedFetchError ,fetchData:fetchFieldData}= useApi(
    '/getThemeFields',
    "POST",
)

const {data:noOfCustomers, loading:customerDataFetching, error:customerDataError ,fetchData:fetchCustomerCount}= useApi(
  '/getThemeRelatedCustomerCount',
  "POST",
)



    const [themeFields,setThemeFields] = React.useState(themeFieldsData)

    useEffect(()=>{
      if(type==='edit' && themeDetails?.tid){

        let body ={
          Theme_id:themeDetails?.tid
        }
        fetchFieldData(body,(res)=>{
          setThemeFields(res?.data?.result)
        })
        fetchCustomerCount(body,(res)=>{})
      }
    },[themeDetails,open])

    const handleRadioChange = (e:any)=>{
      let copy = {...themeFields}
      copy[e?.target?.id] = e?.target?.checked
      setThemeFields(copy)
    }

    const themeRelatedCustomers = type === 'edit' ? noOfCustomers?.data : 0
    
    const onSubmithandler = (values:any,{ setSubmitting}: { setSubmitting: (isSubmitting: boolean) => void }) => {
          const {tname:Theme_name,no_of_records} = values
        if(type==="create"){
          let body = {Theme_name,no_of_records,fields:{...themeFields}}
          createTheme(body,(res)=>{
            if( typeof onSuccess === "function" ){
              onSuccess(res?.data);
          }
          })
        }
        else if (themeRelatedCustomers && themeRelatedCustomers > 0){
          setConfirmModalOpen(true)
        }
        else{
          let body = {no_of_records,Theme_id:themeDetails?.tid,fields:{...themeFields}}
          editTheme(body,(res)=>{
            setConfirmModalOpen(false)
            handleClose()
            if( typeof setFetchThemes === "function" ){
              setFetchThemes(true)
          }
          })
        }
        setSubmitting(false)
    }
  return (
    <BaseModal open={open} handleClose={handleClose}>
         <div className={classes.container}>
            <div className={classes.title}>{`${type} Theme`}</div>
            <Formik
            initialValues={deriveInitalValues(themeDetails?.tname,themeDetails?.no_of_records)}
            validationSchema={validationSchema}
            onSubmit={onSubmithandler}
            >
            {({
                handleBlur,
                handleChange,
                values,
                errors,
                isSubmitting,
            }) => {

              const handleEditTheme = () => {
                const {tname:Theme_name,no_of_records} = values
                let body = {no_of_records,Theme_id:themeDetails?.tid,fields:{...themeFields}}
                editTheme(body,(res)=>{
                  setConfirmModalOpen(false)
                  handleClose()
                  if( typeof setFetchThemes === "function" ){
                    setFetchThemes(true)
                }
                })
              }
              
              return <Form>
                  <TextField 
                    id="tname-input" 
                    label="Theme Name" 
                    variant="outlined" 
                    name='tname'
                    sx={{marginTop:3}}
                    value={values?.tname}
                    error={!!(errors?.tname)}
                    helperText={errors?.tname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={type==="edit"}
                    InputProps={{
                        readOnly: type==="edit",
                    }}
                  />
                  <FormControl sx={{marginTop:3,width:"100%"}}>
                    <InputLabel id="demo-simple-select-helper-label" error={!!(errors?.no_of_records)}>No of Records</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      name='no_of_records'
                      value={values?.no_of_records}
                      error={!!(errors?.no_of_records)}
                      label="No of Records"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem> */}
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={35}>35</MenuItem>
                    </Select>
                    <FormHelperText error={!!(errors?.tname)}>{errors?.tname}</FormHelperText>
                  </FormControl>
                  <div className={classes.fieldSection}>
                    <div className={classes.fieldLabel}>Fields</div>
                    <div className={classes.checkboxContainer}>
                    {Object.keys(themeFields).map((key:any,index:number) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={themeFields?.[key]}
                          onChange={handleRadioChange}
                          id={key}
                          name={key}
                          color="primary"
                        />
                      }
                      label={key}
                    />
                    ))}
                    </div>
                  </div>
                  <div className={classes.actionContainer}>
                      <Button
                          variant='contained'
                          color="secondary"
                          onClick={handleClose}
                      >
                          Cancel
                      </Button>
                      <Button
                          variant='contained'
                          color="primary"
                          type='submit'
                          disabled={isSubmitting}
                      >
                          Save
                      </Button>
                  </div>
                  <ConfirmModal 
                    open={confirmModalOpen}
                    handleClose={handleConfirmModalClose}
                    handleClick={handleEditTheme}
                    text={`This theme is used by ${themeRelatedCustomers ?
                                                            themeRelatedCustomers >1 ?
                                                            themeRelatedCustomers + ' Customers':
                                                            themeRelatedCustomers + ' Customer' :''
                                                  } ,Are you sure you want to  Edit this theme`}
                    actionLabel={"Confirm"}
                />
                </Form>
            }}
          </Formik>
          {themeCreationError &&
            <FormHelperText style={{marginTop:20}} error>{themeCreationError}</FormHelperText>
          }
         </div>
    </BaseModal>
  )
}
