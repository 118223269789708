import { Theme } from "@mui/material/styles";

const styles = (theme:Theme) => ({
   container:{
    width:600,
    padding:30,
   },
   flexContainer:{
      display:"flex",
    flexDirection:"column",
    alignItems:"center",
    width:"100%"
   },
   title:{
      width:"100%",
      display:"flex",
      justifyContent:"center",
    fontSize:22,
    fontWeight:700,
   },
   subTitle:{
    fontSize:18,
    fontWeight:500,
    marginTop:20,
    marginBottom:10
   },
   apikeyContainer:{
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"flex-end",
    marginTop:22,
    '& .MuiButton-root': {
        maxWidth:150 ,
        marginLeft:20
      },
   },
   addNewTheme:{
    width:"100%",
    height:50,
    background: "#4E4EB0 !important",
    justifyContent:"center !important",
    fontSize:"18px !important",
    fontWeight:"500 !important",
    color:"#ffffff !important"
   }

}) as const ;

export default styles;