import { Theme } from "@mui/material/styles";

const styles = (theme:Theme) => ({
    container:{
        width:"100%",
        // minHeight:"100%",
        display:"flex",
        justifyContent:"center",
        alignItems:"flex-start"
    },
    cardContainer:{
        backgroundColor: "#F5F5F5",
        border:"2px solid #E9E9E9",
        borderRadius:0,
        padding:"40px 50px",
        marginTop:150,
        width:700,
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        '& .MuiTextField-root': {
            marginTop: 30
          },
          '& .MuiButton-root': {
            marginTop: 30,
            fontSize:18
          },
        [theme.breakpoints.down("xl")]:{
        marginTop:100,
        width:500
        }
      
    },

    error:{
        width:"100%",
        minHeight:20,
        height:"auto",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        color:"red",
        marginTop:10
    },
    logo:{
        fontSize:35,
        color:'#4E4EB0',
        fontWeight:700
    }

}) as const ;

export default styles;