import React, { useEffect } from 'react'
import DashboardLayout from 'src/layouts/dashboard-layout'
// import { makeStyles } from '@mui/styles';
import ThemeListingTable from 'src/components/theme-listing-table';
import useApi from 'src/hooks/useApi';
// import {data} from "./mockData"
// import styles from "./styles"

// const useStyles = makeStyles(styles)

const tHead = [
  {
    name:"id",
    displayName:"Theme Id",
    width:20
  },
  {
    name:"theme_name",
    displayName:"Theme Name",
    width:50
  },
  {
    name:"no_of_records",
    displayName:"No of Records",
    width:20
  },
]

export default function ManageThemes() {
  const [page,setPage] = React.useState(1)
  const [fetchThemes,setFetchThemes] = React.useState(true)
  const size =8

  const {data:res, loading, error ,fetchData}= useApi(
      '/themes/',
      "GET",
      {page,size}
  )

  const totalPages = res?.data?.TotalPages || 0


  useEffect(()=>{
    fetchData()
    setFetchThemes(false)
  },[fetchThemes,page])



  return (
    <DashboardLayout title='Manage Themes'>
      <ThemeListingTable 
        tHead={tHead}
        tData={res?.data&&res?.data?.Themes ? res?.data?.Themes : []}
        currentPage={page}
        setPage={setPage}
        totalPage={totalPages}
        isFetching={loading}
        setFetchThemes={setFetchThemes}
      />
    </DashboardLayout>
  )
}
