import React from 'react'
import DashboardLayout from 'src/layouts/dashboard-layout'
import { makeStyles } from '@mui/styles';
import styles from './styles';
import Switch from '@mui/material/Switch';

const useStyles = makeStyles(styles)


export default function Settings() {
  const [apiKey,setApiKey] = React.useState(false)
  const [domain,setDomain] = React.useState(false)

  const handleChangeApiKey = () =>{
    setApiKey(!apiKey)
  }

  const handleChangeDomain = () => {
    setDomain(!domain)
  }
  const classes = useStyles()
  return (
    <DashboardLayout title='Settings'>
      <div className={classes.container}>
        <div className={classes.subHeading}>Enable/Disable Secuirity</div>
        <div className={classes.row}>
          <div className={classes.label}>API Key</div>

          {/* <span className={classes.enableLabel}>Enable</span> */}
          <Switch 
            checked={apiKey}
            onChange={handleChangeApiKey}
          />
          {/* <span className={classes.enableLabel}>Disable</span> */}
        </div>
        <div className={classes.row}>
          <div className={classes.label}>Domain</div>

          {/* <span className={classes.enableLabel}>Enable</span> */}
          <Switch 
            checked={domain}
            onChange={handleChangeDomain}
          />
          {/* <span className={classes.enableLabel}>Disable</span> */}
        </div>
      </div>
    </DashboardLayout>
  )
}
