import React from 'react'
import {
    Dialog
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme:Theme) => ({
    dialog: {
      minWidth: 500,
    //   minHeight: 200,
    },
  }));

type ModalProps = {
    open:boolean,
    handleClose:()=>void,
    children:JSX.Element,
    disableEscapeKeyDown?:boolean,
    disableCloseOnEscape?:boolean
}

export default function BaseModal(props:ModalProps) {
    const {handleClose,...otherProps} = props
    const classes = useStyles()
  return (
    <Dialog
        onClose={handleClose}
        {...otherProps}
        classes={{
            paper: classes.dialog,
          }}
    >
        {props.children}
    </Dialog>
  )
}
