import React from 'react'
import { makeStyles } from '@mui/styles';
import {
    Avatar,
    Divider,
    Button
} from '@mui/material';
import { useLocation ,useNavigate} from 'react-router-dom';
import ConfirmModal from 'src/components/confirm-modal';
//import ApplyThemeModal from 'src/components/apply-theme-modal';
import styles from "./styles"

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import Cookies from 'js-cookie';

import { ReactComponent as Logo } from "../../assets/images/argus asia.svg";

const useStyles = makeStyles(styles)

export default function SideBar() {
    const classes = useStyles()
    const location = useLocation();
    const navigate = useNavigate();

    const [modalOpen,setModalOpen] = React.useState(false)

    const handleModalClose = () => {
        setModalOpen(false)
    }

    const handleModalOpen = () => {
        setModalOpen(true)
    }

    const handleNavigate = (to:string)=> ()=>{
        navigate("/"+to)
    }

    const handleLogout = () => {
        Cookies.remove('token');
        Cookies.remove('refreshToken');
        handleModalClose()
        navigate("/")
    }

  return (
    <div className={classes.container}>
        <div>
            <div className={classes.logoContainer}><div className={classes.logo}>Calyx</div></div>
            
            <div className={classes.middleContainer}>
                <Avatar 
                    alt="Admin Test"
                    src="/static/images/avatar/1.jpg"
                    // style={{ width: 100, height: 100 , marginBottom:10 }}
                    className={classes.avatar}
                />
                <div className={classes.username}>Admin Test</div>
                <div className={classes.userType}>Admin</div>

                <Divider className={classes.divider} />

                <Button
                    variant="outlined"
                    color={location?.pathname==="/user-management"?"primary":"secondary"}
                    startIcon={<ManageAccountsIcon />}
                    onClick={handleNavigate("user-management")}
                    >
                    User Management
                </Button>
                <Button
                    variant="outlined"
                    color={location?.pathname==="/manage-themes"?"primary":"secondary"}
                    startIcon={<AutoFixHighIcon />}
                    onClick={handleNavigate("manage-themes")}
                    >
                    Manage Themes
                </Button>
                <Button
                    variant="outlined"
                    color={location?.pathname==="/settings"?"primary":"secondary"}
                    startIcon={<SettingsIcon />}
                    onClick={handleNavigate("settings")}
                    >
                    Settings
                </Button>
            </div>
        </div>
        <div className={classes.logoutContainer}>
            <Button
                variant="contained"
                color={"secondary"}
                startIcon={
                    <div className='icon-container'>
                     <LogoutIcon />
                    </div>
                }
                onClick={handleModalOpen}
                >
                Logout
            </Button>
            <ConfirmModal 
                open={modalOpen}
                handleClose={handleModalClose}
                handleClick={handleLogout}
                text={"Are you sure you want to Logout"}
                actionLabel={"Logout"}
            />
            {/* <ApplyThemeModal 
                open={modalOpen}
                handleClose={handleModalClose}
            />  */}
        </div>
    </div>
  )
}
