import { Theme } from "@mui/material/styles";

const styles = (theme:Theme) => ({
   container:{
    width:"100%",
    display:"flex",
    flexDirection:"column"
   },
   row:{
    display:"flex",
    alignItems:"center"
   },
   label:{
    fontSize:18,
    width:100,
   },
   enableLabel:{
        fontSize:14
   },
   subHeading:{
      fontSize:22,
      marginBottom:20
   }
}) as const ;

export default styles;