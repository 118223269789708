import React from 'react'
import BaseModal from 'src/components/base-modal'
import { Button } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme:Theme) => ({
    container: {
      width:"100%",
      display:"flex",
      flexDirection:"column",
      padding:20
    },
    actionContainer:{
        width:"100%",
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-end",
        marginTop:50,
        '& .MuiButton-root': {
            marginLeft: 20 ,
            maxWidth:100
          },
    }
  }));

type ModalProps = {
    open:boolean,
    handleClose:()=>void,
    handleClick:()=>void,
    text:string,
    actionLabel:string
}


  

export default function ConfirmModal(props:ModalProps) {
    const {open,handleClose,handleClick,text,actionLabel} = props
    const classes = useStyles()
  return (
    <BaseModal open={open} handleClose={handleClose}>
         <div className={classes.container}>
            <div>{text}</div>
            <div className={classes.actionContainer}>
                <Button
                    variant='contained'
                    color="secondary"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    color="primary"
                    onClick={handleClick}
                >
                    {actionLabel}
                </Button>
            </div>
         </div>
    </BaseModal>
  )
}
