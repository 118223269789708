import { Theme } from "@mui/material/styles";

const styles = (theme:Theme) => ({
    container:{
       width:"100%",
       display:"flex",
       flexDirection:"column" ,
    //    height:157,
       justifyContent:"flex-end",
       paddingTop:20
    },
    heading:{
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 36,
        lineHeight: "140%",
        letterSpacing: "-0.006em",
        color: "#000000",
        marginBottom:19
    },
    divider: {
        width: '100%',
        height: '2px',
        backgroundColor: '#E9E9E9',
        background: `linear-gradient(to right, #FFFFFF 0%, #FFFFFF 10%, #E9E9E9 10%, #E9E9E9 100%)`,
        // maxWidth:1030
      },
    
}) as const ;

export default styles;