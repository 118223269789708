import React from 'react';
import ReactPaginate from 'react-paginate';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import styles from './styles';

import { ReactComponent as RightArrowIcon } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as LeftArrowIcon } from "../../assets/icons/arrow-left.svg";

const useStyles = makeStyles(styles)

type CustomePaginationProps = {
	currentPage: number;
	totalPage: number;
	setPage: (page: number) => void;
};
const CustomPagination: React.FC<CustomePaginationProps> = ({
	currentPage,
	totalPage,
	setPage
}) => {
	const style = useStyles()
	let [searchParams, setSearchParams] = useSearchParams();
	
	const handlePageClick = (event: any) => {
		//const newOffset = (event.selected * itemsPerPage) % items.length;
		setPage(event?.selected + 1);
		setSearchParams((params) => {
			params.set('page', event?.selected + 1);
			return params;
		});
	};
	return (
		<ReactPaginate
			initialPage={currentPage - 1}
			breakLabel="..."
			nextLabel={<RightArrowIcon />}
			onPageChange={handlePageClick}
			forcePage={currentPage - 1}
			pageCount={totalPage}
			previousLabel={<LeftArrowIcon />}
			containerClassName={style.paginate}
			pageClassName={`${style.common} ${style.paginateItem}`}
			pageLinkClassName={style.pageLink}
			activeClassName={style.activePage}
			activeLinkClassName={`${style.pageLink} ${style.pageLinkActive}`}
			previousClassName={`${style.common} ${style.previousBtn}`}
			nextClassName={`${style.common} ${style.nextBtn}`}
			nextLinkClassName={style.nextPrevLink}
			previousLinkClassName={style.nextPrevLink}
			disabledClassName={style.disabledBtn}
			breakClassName={style.breakClass}
			breakLinkClassName={style.breakLinkClass}
		/>
	);
};

export default CustomPagination ;