import React, { useState } from 'react'
import { makeStyles } from '@mui/styles';
import CustomPagination from 'src/components/custom-pagination';
import EditIcon from '@mui/icons-material/Edit';
import {
    IconButton,
    Tooltip,
    Snackbar,
    SnackbarContent,
    Skeleton
 } from '@mui/material';
 import EditCustomerModal from 'src/components/edit-customer-modal';
 import ContentCopyIcon from '@mui/icons-material/ContentCopy';
 import copy from 'copy-to-clipboard';


import styles from './style';

const useStyles = makeStyles(styles)

interface THead {
    name: string;
    displayName:string,
    width:number
  }

  interface TData {
    id:number,
    customer_name:string,
    ApiKey:string,
    domain_name:string,
    Theme_name:string,
    actions?:string[],
    username:string,
  }

export const EmptyDataDisplay = () =>{
    const classes = useStyles()

    return(
        <span className={classes.emptyData}></span>
    )
}

export default function UserInfoTable(
    {
        tHead,
        tData,
        currentPage,
        totalPage,
        setPage,
        setFetchCustomers,
        isFetching
    }
    :
    {
        tHead:THead[],
        tData:TData[],
        currentPage: number;
        totalPage: number;
        setPage: (page: number) => void;
        setFetchCustomers:(value:boolean) => void,
        isFetching:boolean
    }
) {
    const classes = useStyles()
    const [editModal,setEditModal] = useState({
        open:false,
        theme:null,
        name:"",
        cid:null,
        domain:"",
        api_key:"",
        username:""
    })

    const [snackBarOpen,setSnackBarOpen] = useState<boolean>(false)

    const copyToClipboard = (apiKey:string) =>async () => {
        try {
        // await navigator.clipboard.writeText(apiKey);
        copy(apiKey);
        setSnackBarOpen(true);
        } catch (err) {
        console.error('Failed to copy:', err);
        }
  };

    const handleCloseSnackBar = () =>{
        setSnackBarOpen(false)
    }

    const handleCloseModal = () => {
        setEditModal({
            open:false,
            theme:null,
            name:"",
            cid:null,
            domain:"",
            api_key:"",
            username:""
        })
    }

    const handleModalOpen = (customer:any) => ()=> {
        setEditModal({
            open:true,
            theme:customer?.Theme_id,
            name:customer?.company,
            cid:customer?.id,
            domain:customer?.domain_name,
            api_key:customer?.ApiKey,
            username:customer?.name
    
        })

    }



  return (
    <div className={classes.container}>
        <div className={classes.tHead}>
            {tHead.map((item:THead,index:number)=>{
                return(
                    <span key={index} style={{width:`${item.width}%`}}>{item.displayName}</span>
                )
            })}
            <span style={{width:"5%"}}>Edit</span>
        </div>
        <div className={`${classes.tData} ${!isFetching&&!(tData?.length)&&classes.noData}`}>
            {!isFetching&&!!(tData?.length)&&tData.map((tDataItem:any,index:number)=>{
                return(
                    <div className={`${classes.tDataRow} ${index===7&&classes.lastTData}`}>
                        {tHead.map((item:any,index:number)=>{
                            if(item?.name==='ApiKey')
                                return(
                                    <span key={index} style={{width:`${item.width}%`,display:"flex",alignItems:"center"}}>
                                        {tDataItem?.[item.name]?tDataItem?.[item.name]?.slice(0, 4)+'****':<EmptyDataDisplay />}
                                        {tDataItem?.[item.name] &&
                                            <Tooltip title='copy apikey'>
                                                <IconButton sx={{marginLeft:3}} onClick={copyToClipboard(tDataItem?.[item.name])}>
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </span>
                                )
                            return(
                                    <span style={{width:`${item.width}%`}}>{tDataItem?.[item.name] ? tDataItem?.[item.name] :<EmptyDataDisplay />}</span>
                                )
                        })} 
                        <span style={{width:"5%"}}>
                            <IconButton aria-label="edit" onClick={handleModalOpen(tDataItem)}>
                                <EditIcon />
                            </IconButton>
                        </span>
                    </div>
                )
            })}
            {!isFetching&&!(tData?.length)&&
                <div>
                    No data found
                </div>
            }
            {isFetching&&[0,1,2,3,4,5,6,7].map((item:number,index:number)=>{
                return <Skeleton key={index} variant="rectangular" width={"100%"} height={77} animation={index%2===0?'pulse':"wave"} />
            })
            }
        </div>
        {!isFetching&&totalPage > 0 &&
            <div className={classes.paginationContainer}>
                <span className={classes.index}>{`${currentPage} of ${totalPage} Results`}</span>
                {totalPage >1 &&
                <CustomPagination 
                    currentPage={currentPage}
                    setPage={setPage}
                    totalPage={totalPage}
                />
                }
            </div>
        }
        <EditCustomerModal 
            open={editModal?.open}
            handleClose={handleCloseModal}
            customer={editModal}
            setFetchCustomers={setFetchCustomers}
        />
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            open={snackBarOpen}
            autoHideDuration={1000}
            onClose={handleCloseSnackBar}
            message="Copied to clipboard"
            key={'bottom-center'}
      >
        <SnackbarContent message={<span className={classes.message}>Copied to clipboard</span>} />
      </Snackbar>
    </div>
  )
}
