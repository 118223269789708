import { Theme } from "@mui/material/styles";

const styles = (theme:Theme) => ({
    container:{
        position:"fixed",
        overflowY:"auto",
        overflowX:"hidden",
        width:"100%",
        zIndex:999,
        maxWidth:360,
        height:"100%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        padding:"48px 70px 62px 70px",
        border:"2px solid #E9E9E9",
        alignItems:"center",
        '&::-webkit-scrollbar': {
            width: 5
          },
          '&::-webkit-scrollbar-thumb':{
            backgroundColor: "#ccc",
            borderRadius: 10,
          },
          '&::-webkit-scrollbar-track':{
            backgroundColor: '#f1f1f1',
            borderRadius: 10
          },
        [theme.breakpoints.down("xl")]:{
            padding:"28px 30px 28px 30px",
            maxWidth:320
        }
    },
    middleContainer:{
        marginTop:20,
        width:"100%",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        '& .MuiButton-root': {
            marginBottom: 40 ,
            // justifyContent:"flex-start"
          },
          [theme.breakpoints.down("xl")]:{
            marginTop:10,
            '& .MuiButton-root': {
                marginBottom: 20 ,
                // justifyContent:"flex-start"
              },
        }

    },
    avatar:{
        '&.MuiAvatar-root':{
        width: 100, 
        height: 100 , 
        marginBottom:10,
        [theme.breakpoints.down("xl")]:{
            width: 70, 
            height: 70 , 
            marginBottom:5,
        }
        }
    },
    username:{
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 24,
        // lineHeight: "140%",
        // letterSpacing: "-0.006em",
        color: "#000000",
        marginBottom:2,
        [theme.breakpoints.down("xl")]:{
            fontSize:22
        }
    },
    userType:{
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 20,
        // lineHeight: "140%",
        // letterSpacing: "-0.006em",
        color: "#677480",
        marginBottom:49,
        [theme.breakpoints.down("xl")]:{
            fontSize:18,
            marginBottom:29,
        }
    },
    divider:{
        width: '100%', 
        height: '2px', 
        backgroundColor: '#F3F1EF',
        marginBottom:"66px !important",
        [theme.breakpoints.down("xl")]:{
            marginBottom:"26px !important",
        }
    },
    logoutContainer:{
        width:"100%",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        '& .MuiButton-root': {
            maxWidth:111,
            backgroundColor:"transparent",
            boxShadow:"none",
            '& .MuiSvgIcon-root': {
                fill: '#FFFFFF',
              },
            "&:hover":{
                backgroundColor:"transparent",
                boxShadow:"none",
            }
          },
        "& .icon-container":{
            borderRadius:"50%",
            backgroundColor:"#4E4EB0",
            width:36,
            height:36,
            display:"flex",
            alignItems:"center",
            justifyContent:"center"
        },
    },
    logo:{
        fontSize:35,
        color:'#4E4EB0',
        fontWeight:700,
    },
    logoContainer:{
        width:"100%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    }
}) as const ;

export default styles;