import { Theme } from "@mui/material/styles";

const styles = (theme:Theme) => ({
    common:{
        borderRadius: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 45,
        height:45,
        cursor: "pointer",
        color:"#4B4B4B"
    },
    paginate:{
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      listStyleType: "none",
      //marginTop: 28
    },
    paginateItem:{
        marginRight: 5
    },
    activePage:{
        backgroundColor:  "#4E4EB0",
        color:"#FFFFFF",
        borderRadius: 5
    },
    previousBtn:{
        marginRight: 5,
        backgroundColor: "#DEDEDE"
    },
    nextBtn:{
        backgroundColor: "#DEDEDE",
    },
    disabledBtn:{
        backgroundColor: "#F5F5F5"
    },
    pageLink:{
        width:"100%",
        height:"100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color:"#4B4B4B",
        textDecoration: "none",
        "&:link,&:visited,&:hover,&:active":{
            color:"#4B4B4B"
        }
    }, 
    pageLinkActive:{
        color:"#FFFFFF",
        textDecoration: "none",
        "&:link,&:visited,&:hover,&:active":{
            color:"#FFFFFF"
        }
    },
    nextPrevLink:{
        width:"100%",
        height:"100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    breakClass:{
        borderRadius: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 45,
        height:45,
        color:"#4B4B4B"
    },
    breakLinkClass:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height:"100%",
        textDecoration: "none",
        color:"#4B4B4B",
        "&:link,&:visited,&:hover,&:active":{
            color:"#4B4B4B"
        }
    }
    

}) as const ;

export default styles;