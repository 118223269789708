import React from 'react'
import { makeStyles } from '@mui/styles';
import SideBar from '../../components/sidebar';
import AppHeader from '../../components/app-header';
import styles from "./styles"

const useStyles = makeStyles(styles)

export default function DashboardLayout(
    {
        title,
        children
    }:
    {
        title:string,
        children:JSX.Element
    }
) {
    const classes = useStyles()
    
  return (
    <div className={classes.container}>
        <SideBar />
        <div className={classes.rightContainer}>
            <AppHeader title={title} />
            <div className={classes.childrenContainer}>{children}</div>
        </div>
    </div>
  )
}
