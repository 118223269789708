import React from 'react';
import 'typeface-inter';
import AppRouter from './router';
import { ThemeProvider} from '@mui/material/styles';
import theme from "./theme";
import "./styles/base.css"

function App() {
  return (
    <ThemeProvider theme={theme}>
        <AppRouter />
    </ThemeProvider>
  );
}

export default App;
