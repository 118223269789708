import React, { useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import styles from './styles';
import { 
    TextField,
    Button
} from '@mui/material';
import { useNavigate,useLocation } from 'react-router-dom';
import { Formik,Form } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as Logo } from "../../assets/images/argus asia.svg";
import useApi from 'src/hooks/useApi';
import Cookies from 'js-cookie';
interface LoginFormValues {
    email: string;
    password: string;
  }
  
  const initialValues: LoginFormValues = {
    email: '',
    password: '',
  };
  
  const validationSchema = Yup.object({
    email: Yup.string().email('Please enter a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });




const useStyles = makeStyles(styles)



export default function LoginPage() {
    const classes = useStyles()
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const q = queryParams.get('q');

    const [otherError,setOtherError] = React.useState<string|null>(q&&q=='unauthorized'?"Login Required":"")
    let authNotRequired= true
    const {data, loading, error ,fetchData}= useApi(
        '/adminLogin',
        "POST",
        undefined,
        authNotRequired
    )

    useEffect(()=>{
        error&&setOtherError(error)
    },[q,error])

    const handleLogin = async (values:LoginFormValues) => {
        let body = {
            admin_email:values?.email,
            admin_password:values?.password
        }
        let accessToken = null
        let refreshToken = null
        fetchData(body,(res)=>{
            accessToken=res?.data?.accessToken
            refreshToken= res?.data?.refreshToken
            Cookies.set('token', accessToken, { expires: 3 });
            Cookies.set('refreshToken', refreshToken, { expires: 3 });
            navigate("/user-management")
        })
    }
  return (
    <div className={classes.container}>
       <div className={classes.cardContainer}>
            {/* <Logo /> */}
            <div className={classes.logo}>Calyx</div>
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
            >
            {({
                handleBlur,
                handleChange,
                values,
                errors,
                isSubmitting,
            }) => (
                <Form>
                    <TextField 
                        id="email-input" 
                        name='email'
                        label="Email" 
                        variant="outlined" 
                        value={values?.email}
                        error={!!(errors?.email)}
                        helperText={errors?.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField 
                        id="password-input" 
                        name="password"
                        label="Password" 
                        variant="outlined" 
                        type="password"
                        value={values?.password}
                        autoComplete="current-password"
                        error={!!(errors?.password)}
                        helperText={errors?.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <Button
                        color='primary'
                        variant='contained'
                        type='submit'
                        disabled={isSubmitting}
                    >
                        Login
                    </Button>
                    <div className={classes.error}> 
                        {otherError}
                    </div>
                </Form>
            )}
            </Formik>
       </div>
    </div>
  )
}
