import React, { useEffect } from 'react'
import DashboardLayout from 'src/layouts/dashboard-layout'
import UserInfoTable from 'src/components/user-info-table'
import useApi from 'src/hooks/useApi';


const tHead = [
  {
    name:"id",
    displayName:"Customer Id",
    width:10
  },
  {
    name:"name",
    displayName:"Name",
    width:20
  },
  {
    name:"company",
    displayName:"Company",
    width:15
  },
  {
    name:"ApiKey",
    displayName:"API Key",
    width:15
  },
  {
    name:"domain_name",
    displayName:"Domain",
    width:25
  },
  {
    name:"Theme_name",
    displayName:"Theme",
    width:20
  },
]

export default function UserManagement() {
    const [page,setPage] = React.useState(1)
    const [fetchCustomers,setFetchCustomers] = React.useState(true)
    const size = 8

    const {data:response, loading, error ,fetchData}= useApi(
                                                      '/getCustomers',
                                                      "GET",
                                                      {page,size}
                                                  )
      const totalPages = response?.data?.TotalPages || 0

    useEffect(()=>{
        fetchData()
        setFetchCustomers(false)
    },[fetchCustomers,page])
  


  return (
    <DashboardLayout title='User Management'>
      <UserInfoTable 
        tHead={tHead}
        tData={response?.data&&response?.data?.customers?response?.data?.customers:[]}
        currentPage={page}
        setPage={setPage}
        totalPage={totalPages}
        setFetchCustomers={setFetchCustomers}
        isFetching={false}
      />
    </DashboardLayout>
  )
}
