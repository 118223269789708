import React from 'react'
import { makeStyles } from '@mui/styles';
import CustomPagination from 'src/components/custom-pagination';
import EditIcon from '@mui/icons-material/Edit';
import {
    IconButton,
    Skeleton
 } from '@mui/material';
 import ThemeModal from 'src/components/theme-modal';
 import { EmptyDataDisplay } from 'src/components/user-info-table';

import styles from './style';

const useStyles = makeStyles(styles)

interface THead {
    name: string;
    displayName:string,
    width:number
  }

  interface TData {
    tid:number,
    tname:string,
    no_of_records:number,
  }

export default function ThemeListingTable(
    {
        tHead,
        tData,
        currentPage,
        totalPage,
        setPage,
        isFetching,
        setFetchThemes
    }
    :
    {
        tHead:THead[],
        tData:TData[],
        currentPage: number;
        totalPage: number;
        setPage: (page: number) => void;
        isFetching:boolean,
        setFetchThemes:(value:boolean) => void
    }
) {
    const classes = useStyles()
    const [theme,setTheme] = React.useState({
        open:false,
        tid:null,
        tname:"",
        no_of_records:null,
    })


    const handleCloseModal = () => {
        setTheme({
            open:false,
            tid:null,
            tname:"",
            no_of_records:null,
        })
    }

    const handleModalOpen = (theme:any) => ()=> {
        setTheme({
            open:true,
            tid:theme?.id,
            tname:theme?.theme_name,
            no_of_records:theme?.no_of_records,
    
        })

    }


  return (
    <div className={classes.container}>
        <div className={classes.tHead}>
            {tHead.map((item:THead)=>{
                return(
                    <span style={{width:`${item.width}%`}}>{item.displayName}</span>
                )
            })}
            <span style={{width:"10%"}}>Edit</span>
        </div>
        <div className={`${classes.tData} ${!isFetching&&!(tData?.length)&&classes.noData}`}>
            {!isFetching&&!!(tData?.length)&&tData.map((tDataItem:any,index:number)=>{
                return(
                    <div className={`${classes.tDataRow} ${index===7&&classes.lastTData}`}>
                        {tHead.map((item:any)=>{
                            if(item?.name==='no_of_records')
                                return(
                                    <span style={{width:`${item.width}%`}}>{tDataItem?.[item.name]?tDataItem?.[item.name]:<EmptyDataDisplay />}</span>
                                )
                            return(
                                    <span style={{width:`${item.width}%`}}>{tDataItem?.[item.name]}</span>
                                )
                        })} 
                        <span style={{width:"10%"}}>
                            <IconButton aria-label="edit" onClick={handleModalOpen(tDataItem)}>
                                <EditIcon />
                            </IconButton>
                        </span>
                    </div>
                )
            })}
            {!isFetching&&!(tData?.length)&&
                <div>
                    No data found
                </div>
            }
            {isFetching&&[0,1,2,3,4,5,6,7].map((item:number,index:number)=>{
                return <Skeleton variant="rectangular" width={"100%"} height={77} animation={index%2===0?'pulse':"wave"} />
            })
            }
        </div>
        {!isFetching&&totalPage > 0 &&
            <div className={classes.paginationContainer}>
                <span className={classes.index}>{`${currentPage} of ${totalPage} Results`}</span>
                {totalPage >1 &&
                    <CustomPagination 
                        currentPage={currentPage}
                        setPage={setPage}
                        totalPage={totalPage}
                    />
                }
            </div>
        }
        <ThemeModal 
              open={theme?.open}
              handleClose={handleCloseModal}
              type="edit"
              themeDetails={theme}
              setFetchThemes={setFetchThemes}
          />
    </div>
  )
}
