import React,{useEffect} from 'react'
import BaseModal from 'src/components/base-modal'
import { 
  Button ,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  CircularProgress,
  IconButton,
  FormHelperText
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Formik,Form } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';
import styles from './styles';
import CachedIcon from '@mui/icons-material/Cached';
import ConfirmModal from 'src/components/confirm-modal';
import ThemeModal from 'src/components/theme-modal';
import { deriveInitalValues } from './validationSchema';
import useApi from 'src/hooks/useApi';

const useStyles=makeStyles(styles)

type ModalProps = {
    open:boolean,
    handleClose:()=>void,
    customer:any,
    onSuccess?:(values:any)=>void,
    setFetchCustomers:(value:boolean) => void
}

type FormValues = {
  tId: string;
  domain: string;
  apiKey:string,
};



const validationSchema = Yup.object({
  // cname: Yup.string().required('This field is required'),
  tId: Yup.string()
  .required('This field is required'),
  domain:Yup.string().required('This field is required').matches(/^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/, 'Invalid domain'),
  apiKey:Yup.string().required('This field is required')
});



export default function EditCustomerModal(props:ModalProps) {
    const {open,handleClose,customer,setFetchCustomers} = props
    const [fetchTheme,setFetchTheme] = React.useState<boolean>(true)
    const [openSelect, setOpenSelect] = React.useState<boolean>(false);

    const handleCloseSelect = () => {
      setOpenSelect(false);
    };
  
    const handleOpenSelect = () => {
      setOpenSelect(true);
    };
    const {data:response, loading, error ,fetchData}= useApi(
      '/themes',
      "GET",
  )


  // console.log("customers: ",customer)

  const {data:updatedCustomerData, loading:updateCustomerLoading, error:updatedCustomerError ,fetchData:updateCustomerData}= useApi(
    '/admin/addUser',
    "POST",
)

    const {data:apiKeyData, loading:apiKeyGenerating, error:apiKeyGenerationError ,fetchData:generateApiKey}= useApi(
      '/generateApiKey',
      "POST",
     )

    // console.log("save data : ",updatedCustomerData)
    // console.log("error : ",updatedCustomerError)
    //  console.log("error : ",apiKeyGenerationError)

      useEffect(()=>{
        if(fetchTheme){
            fetchData()
            setFetchTheme(false)
        }
      },[fetchTheme])

    const classes = useStyles()

    const [regenerateAPIConfirm,setregenerateApIConfirm] = React.useState(false)

    const handleRegenerateModalOpen = () => {
        setregenerateApIConfirm(true)
    }
    const handleRegenerateModalClose= () => {
        setregenerateApIConfirm(false)
    }


    const [themeModalOpen,setThemeModalOpen] = React.useState(false)

    const handleThemeModalOpen = () =>{
        setThemeModalOpen(true)
    }

    const handleThemeModalClose = ()=>{
        setThemeModalOpen(false)
    }


  const onSubmitHandler = (values:FormValues) => {
      let body ={
        customer_id:customer?.cid,
        // customer_name: values?.cname,
        ApiKey: values?.apiKey,
        domain_name: values?.domain,
        // Theme_name: values,
        Theme_id:values?.tId
    }  
    updateCustomerData(body,(res)=>{
      handleClose()
      setFetchCustomers(true)
    })  
  }

  // console.log("select open : ",openSelect)

  const initialValues:FormValues = deriveInitalValues(customer?.theme,customer?.domain,customer?.api_key)

  return (
    <BaseModal open={open} handleClose={handleClose} disableCloseOnEscape disableEscapeKeyDown>
         <div className={classes.container}>
            <div className={classes.title}>Edit Customer</div>
            <div className={classes.subTitle}>{customer?.name?customer?.name:customer?.username}</div>
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitHandler}
            >
            {({
                handleBlur,
                handleChange,
                values,
                errors,
                isSubmitting,
                setFieldValue
            }) => {

  const handleClick = () => {
    handleRegenerateModalClose()
    generateApiKey({},(res)=>{
      setFieldValue('apiKey', res?.data?.NewKey)});
  };

  const onCreateThemeSuccess = (v:any) => {
    handleThemeModalClose()
    setFetchTheme(true)
    setFieldValue('tId',v?.createdTheme?.id)
    handleCloseSelect()
  }
                return <Form>
                  {/* <TextField 
                    id="customer-name" 
                    name='cname'
                    label="Name" 
                    variant="outlined" 
                    sx={{marginTop:3}}
                    value={values?.cname}
                    error={!!(errors?.cname)}
                    helperText={errors?.cname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={true}
                    InputProps={{
                        readOnly: true,
                    }}
                  /> */}
                  <FormControl sx={{marginTop:3,width:"100%"}}>
                    <InputLabel id="demo-simple-select-helper-label" error={!!(errors?.tId)}>Theme Name</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      name='tId'
                      value={values?.tId}
                      id="demo-simple-select-helper"
                      label="Theme Name"
                      error={!!(errors?.tId)}
                      open={openSelect}
                      onClose={handleCloseSelect}
                      onOpen={handleOpenSelect}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem> */}
                      {response?.data&&response?.data?.Themes&&response?.data?.Themes?.map((theme:{id:number,theme_name:string},index:number) =>{
                          return <MenuItem value={theme?.id}>{theme?.theme_name}</MenuItem>
                      })
                      }
                      <MenuItem onClick={handleThemeModalOpen} className={classes.addNewTheme}>
                          + Add new Theme
                      </MenuItem>
                    </Select>
                    <FormHelperText 
                      id="my-helper-text"
                      error={!!(errors?.tId)}
                    >
                      {errors?.tId?errors?.tId:''}
                    </FormHelperText>
                  </FormControl>
                  <TextField 
                      id="domain-input" 
                      name='domain'
                      value={values?.domain}
                      error={!!(errors?.domain)}
                      helperText={errors?.domain}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Domain" 
                      variant="outlined" 
                      sx={{marginTop:3}}
                  />
                  <div className={classes.apikeyContainer}>
                      <TextField 
                          id="apiKey-input" 
                          name='apiKey'
                          value={values?.apiKey}
                          error={!!(errors?.apiKey)}
                          helperText={errors?.apiKey}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="API Key" 
                          variant="outlined" 
                          disabled={true}
                          InputProps={{
                              readOnly: true,
                          }}
                      /> 
                      <IconButton onClick={handleRegenerateModalOpen} >
                          {apiKeyGenerating? <CircularProgress size={24} /> : <CachedIcon />}
                      </IconButton>
                  </div>
                  <div className={classes.apikeyContainer}>
                      <Button
                          variant='contained'
                          color="secondary"
                          onClick={handleClose}
                      >
                          Cancel
                      </Button>
                      <Button
                          variant='contained'
                          color="primary"
                          type='submit'
                          disabled={isSubmitting}
                      >
                          Save
                      </Button>
                  </div>
                  <ConfirmModal 
                      open={regenerateAPIConfirm}
                      text={"Are you sure you want to Regenerate API key"}
                      actionLabel="Regenerate"
                      handleClick={handleClick}
                      handleClose={handleRegenerateModalClose}
                  />
                  <ThemeModal 
                    open={themeModalOpen}
                    handleClose={handleThemeModalClose}
                    type="create"
                    onSuccess={onCreateThemeSuccess}
                  />
                </Form>
            }}
            </Formik>
         </div>
    </BaseModal>
  )
}
