import React from 'react'
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './privateRoute';

import LoginPage from '../pages/login';
import UserManagement from '../pages/user-management';
import Settings from '../pages/settings';
import ManageThemes from '../pages/manage-themes';

export default function AppRouter() {
  return (
    <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/user-management" element={
                                  <PrivateRoute>
                                    <UserManagement />
                                  </PrivateRoute>
                                  } 
            />
        <Route path="/manage-themes" element={
                                <PrivateRoute>
                                  <ManageThemes />
                                </PrivateRoute>
                                } 
          />
        <Route path="/settings" element={
                              <PrivateRoute>
                                <Settings />
                              </PrivateRoute>
                              } 
        />
    </Routes>
  )
}
