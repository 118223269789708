export const themeFieldsData:Record<string, boolean>= {
    'GHG_Standard':true,
    'Project_Id': true,
    'Project_Name': true,
    'Project_Type_Group' : true,
    'Project_Type': true,
    'Project_Region' : true,
    'Project_Country': true,
    'Project_State': true,
    'Project_Start_Year': true,
    'Project_End_Year': true,
    'GHG_Risk_Rating': true,
    'GHG_Risk_Rating_Parent': true,
    'GHG_Risk_Rating_Date': true,
    'SDG_Impact_Rating': true,
    'SDG_Certificate' : true,
    'SDG_Certificate_Date': true,
    'Nature' : true,
    'Removal': true,
    'CORSIA': true,
    'Project_Parent': true,
    'About': true,
    'GHG_Methodology': true,
    'Proponent': true,
    'GHG_Risk_Rating_Narrate': true,
    'Additionality': true,
    'Permanence': true,
    'Baseline': true,
    'Project_Emissions': true,
    'Leakage': true,
    'Overlapping_Claims': true,
    'GHG_Risk_Rating_Notes': true,
    'SDG_Impact_Rating_Narrate': true,
    'SDG_1': true,
    'SDG_2': true,
    'SDG_3': true,
    'SDG_4': true,
    'SDG_5': true,
    'SDG_6': true,
    'SDG_7': true,
    'SDG_8': true,
    'SDG_9': true,
    'SDG_10': true,
    'SDG_11': true,
    'SDG_12': true,
    'SDG_13': true,
    'SDG_14': true,
    'SDG_15': true,
    'SDG_16': true,
    'SDG_17': true,
    'SDG_Impact_Rating_Notes': true,
    'Visible_On_Web': true,
    'Carbon_Credit_Notes': true,
    'Upload_Date': true,
    'Revision_Date':true,
}