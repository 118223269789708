import { Theme } from "@mui/material/styles";

const styles = (theme:Theme) => ({
    container:{
        width:"100%",
        height:"100%",
        display:"flex",
    },
    rightContainer:{
        width:"100%",
        display:"flex",
        flexDirection:"column",
        padding:"20px 20px 20px 380px",
        [theme.breakpoints.down("xl")]:{
            padding:"0px 10px 10px 330px",
        }
    },
    childrenContainer:{
        width:"100%",
        display:"flex",
        flexDirection:"column",
        paddingTop:35,
        paddingBottom:70,
        [theme.breakpoints.down("xl")]:{
            
        }
    }
}) as const ;

export default styles;