import React from 'react'
import { makeStyles } from '@mui/styles';
import { Divider } from '@mui/material';
import styles from "./styles"

const useStyles = makeStyles(styles)

export default function AppHeader(
    {
        title
    }
    :
    {
        title:string
    }
) {
    const classes  = useStyles()
  return (
    <div className={classes.container}>
        <div className={classes.heading}>{title}</div>
        <Divider style={{width:"10%",backgroundColor:"#4E4EB0",height:4,}} />
        <Divider className={classes.divider} />
    </div>
  )
}
